<template>
  <b-modal
    v-model="modalShow"
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    size="lg"
  >
    <template #modal-header>
      <h5>{{ title }}</h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypeContractForm"
          >
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Fecha de viaje"
                    label-for="typecontract-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fecha de viaje"
                      vid="Fecha de viaje"
                      rules="required"
                    >
                      <b-form-input
                        id="typecontract-name"
                        v-model="name"
                        placeholder="Fecha de viaje"
                        :state="errors.length > 0 ? false:null"
                        name="typecontract-name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha Inicio"
                    vid="Fecha Inicio"
                    rules="required"
                  >
                    <label for="example-input">Fecha Inicio</label>
                    <b-form-datepicker
                      id="fecha_inicio1"
                      v-model="date_init"
                      placeholder="Fecha inicio"
                      locale="es"
                      today-button
                      reset-button
                      close-button
                      label-close-button="Cerrar"
                      label-reset-button="Limpiar"
                      label-today-button="Hoy"
                      menu-class="w-100"
                      calendar-width="100%"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha fin"
                    vid="Fecha fin"
                    rules="required"
                  >
                    <label for="example-input">Fecha fin</label>
                    <b-form-datepicker
                      id="date_finish1"
                      v-model="date_finish"
                      placeholder="Fecha de Fin"
                      locale="es"
                      today-button
                      reset-button
                      close-button
                      label-close-button="Cerrar"
                      label-reset-button="Limpiar"
                      label-today-button="Hoy"
                      menu-class="w-100"
                      calendar-width="100%"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: true,
      default: () => null,
    },
  },
  data() {
    return {
      name: null,
      date_init: null,
      date_finish: null,
      id: null,
      value: [],
      options: [],
      permissionsByRole: null,
      // validation rules
      required,
    }
  },
  // created() {
  //   this.getCurrentRole()
  // },
  computed: {
    title() {
      return this.infoData ? 'Editar tipo de contrato' : 'Crear tipo de contrato'
    },
  },
  watch: {
    infoData(val) {
      this.clear()
      this.name = val.name
      this.date_init = val.date_init
      this.date_finish = val.date_finish
      this.id = val.id
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    clear() {
      this.name = null
      this.date_init = null
      this.date_finish = null
    },
    save() {
      this.$refs.TypeContractForm.validate().then(success => {
        if (success) {
          if (Object.keys(this.infoData).length > 0) {
            this.$http.put(`/v1/fecha-viaje/update/${this.id}`, {
              name: this.name,
              date_init: this.date_init,
              date_finish: this.date_finish,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El tipo de contrato se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          } else {
            this.$http.post('/v1/fecha-viaje/create', {
              name: this.name,
              date_init: this.date_init,
              date_finish: this.date_finish,
              is_active: 1,
            }).then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El tipo de contrato se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
              this.clear()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              },
              {
                position: 'bottom-right',
              })
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
